$main-color: #cad5ca;
$navbar-light-color: rgba(0,0,0,.5);
$navbar-light-hover-color: rgba(0,0,0,.7);
$navbar-light-active-color: rgba(0,0,0,.8);

@import "fonts";
@import "bootstrap";

@mixin rounded-box() {
  border-radius: 1.5rem; /* @16px = 24px */
}

body {
  background: url(/images/background.jpg) no-repeat center center fixed;
  background-size: cover;
}

.container-white {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  background-color: white;
  padding: 1.5rem; /* @16px = 24px */
  @include rounded-box();
}

.navigation {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  font-family: $alt-font-family;
  font-size: 3rem;
  position: relative;

  @media screen and (max-width: 991px) {
    margin-bottom: 1rem;
  }
}

.navbar-nav .nav-item {
  position: relative;
  line-height: 1.8rem;

  &:not(:first-child) {
    border-left: 2px solid $main-color;
    padding-left: 1rem;
  }

 @media screen and (max-width: 991px) {
    float: none;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
    line-height: 2.2rem;
  }
}

.menu-toggler {
  padding-top: 0.5em;

  .icon-bar {
    background-color: #373a3c;
    display: block;
    width: 21px;
    height: 1px;
    border-radius: 1px;
  }

  .icon-bar+.icon-bar {
    margin-top: 5px;
  }

  &:hover {
    background-color: #f9f9f9;
  }
}

.nav-arrow {
  position: absolute;
  width: 2.5rem;
  margin-top: 0.3rem;
  margin-left: 1rem;
}

.nav-arrow-btn {
  position: absolute;
  width: 5.5rem;
  margin-top: 5.4rem;
  margin-left: 1rem;
}

.btn-nav-group {
  position: absolute;
  right: 4rem;
  top: -5.5rem;
}

.btn-nav {
  @include button-variant($gray-dark, $main-color, #fff);
  font-family: $headings-font-family;
  border-radius: 100%;
  border-width: 2px;
  font-size: 2.5rem;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3rem;
  margin-left: 2rem;
  position: relative;

  sub {
    position: absolute;
    bottom: 0.5rem;
    left: 2.1rem;
    font-family: $alt-font-family;
  }
}

@media screen and (max-width: 543px) {
  .btn-nav-group {
    right: 2.5rem;
  }
  .btn-nav {
    margin-left: 1rem;

    &:first-child {
      margin-left: 0rem;
    }
  }
}

.white-box {
  @include rounded-box();
  border: 3px solid $main-color;
  padding: 2rem 2rem;
  margin-bottom: 2rem;

  p:last-child {
    margin-bottom: 0;
  }
}

.colored-box {
  @include rounded-box();
  background-color: $main-color;
  padding: 2rem 2rem;
  margin-bottom: 2rem;
  position: relative;

  &:before {
    content: " ";
    @include rounded-box();
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    pointer-events: none;
    border: 2px solid #fff;
  }
}

.list-unstyled ul {
  list-style: none;
}

.btn-main {
  @include button-variant($gray-dark, $main-color, $main-color);
  @include rounded-box();
  font-family: $headings-font-family;
  font-size: 1.7rem;
  padding-left: 3rem;
  padding-right: 3rem;

  &.btn-main-bordered {
    @include button-variant($gray-dark, $main-color, $main-color);
    @include rounded-box();
    position: relative;

    &:before {
      content: " ";
      @include rounded-box();
      position: absolute;
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;
      border: 2px solid #fff;
    }
  }
}

.gallery-container {
  margin-left: -4px;
}

.gallery-row {
  display: block;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.gallery-loading {
  padding-left: 4px;
}

.gallery-col {
  padding-left: 4px;
  margin-bottom: 4px;
  float: left;
  font-size: 0; // Removing the space between inlin-block element.

  width: 50%;

  a {
    position: relative;
    display: inline-block;
    background-color: #000;
    text-decoration: none;
  }

  a:after {
    position: absolute;
    content: '+';
    text-align: center;
    font-size: 52px;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    right: 0;
    margin: auto;
    color: transparent;
    transition: all .2s linear;
  }

  a:hover {
    &:after {
      color: #fff;
    }

    img {
      opacity: 0.5;
    }
  }
}

.gallery-title {
  padding-left: 4px;
  margin-top: 54px;
  margin-bottom: 18px;
  text-align: center;
}

// @media (min-width: 544px) {
@media (min-width: 423px) {
  .gallery-col {
    width: 33.3333333%;
  }
}

@media (min-width: 768px) {
  .gallery-col {
    width: 25%;
  }
}

@media (min-width: 992px) {
  .gallery-container {
    margin-left: -18px;
  }

  .gallery-loading {
    padding-left: 18px;
  }

  .gallery-col {
    width: 20%;
    padding-left: 18px;
    margin-bottom: 16px;
  }

  .gallery-title {
    padding-left: 18px;
  }
}

// @media (min-width: 1200px) {
// }


#home {
  .btn-main {
    min-width: 14rem;
    line-height: 3;
  }

  .stamp-text {
    font-size:1.2em;
    font-weight: bold;
    color:red;
    padding:10px 20px;
    margin-bottom: 32px;
    border:10px solid red;
    -webkit-mask-size: contain;
    -webkit-mask-image: url("/images/crack-grunge-mask.png");
    -o-mask-image:url("/images/crack-grunge-mask.png");
    -moz-mask-image:url("/images/crack-grunge-mask.png");
    -ms-mask-image:url("/images/crack-grunge-mask.png");
    mask-image: url("/images/crack-grunge-mask.png");
    mask-size: contain;
    
    @media (min-width: 768px) {
      margin: 60px 32px;
      font-size: 1.6em;
      transform:rotate(-5deg);
    }
    
    @media (min-width: 992px) {
      font-size: 1.8em;
    }
  }
}


#ueber {
  .miriam {
    @include rounded-box();
    // width: 15rem;
  }
}

#foto {
  .gallery-preview {
    position:relative;

    &:hover:after {
      content: " ";
      @include rounded-box();
      z-index: 10;
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.4);
    }
  }
}

#kontakt {
  #contact-form {
    .form-control {
      @include rounded-box();
    }
  }

  .thumbs-up {
    width: 100px;
  }
}

#portfolio {
  .portfolio-nav {
    .nav-item {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 543px) {
    .portfolio-nav {
      margin-left: -0.2rem;
      margin-right: -0.2rem;

      .nav-item {
        padding-left: 0.2rem;
        padding-right: 0.2rem;

        .nav-link {
          padding: 0.3rem 0.1rem 0.3rem 0.1rem;
          overflow: hidden;
          border-radius: 1rem;

          &:before {
            border: none;
          }
        }
      }
    }
  }
}

#kundengalerie {
  .form-control {
    border-radius: 1.5rem;
    max-width: 400px;
  }
}


#error-page {
  .home-btn {
    padding: 0.5em 1em;
  }
}


#footer {
  margin-top: 1rem;
  margin-bottom: 2rem;

  .logo {
    width: 15rem;
    display: inline-block;
  }

  .address {
    font-size: 1.8rem;

    a {
      color: $body-color;
    }
  }
}
