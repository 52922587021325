@font-face {
  font-family: 'Tall Films';
  src: url('../fonts/tall-films.woff2') format('woff2'),
       url('../fonts/tall-films.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$font-family-base:      'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
$alt-font-family:       'Mr De Haviland', Georgia, "Times New Roman", Times, serif;
$headings-font-family:  'Tall Films', 'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-h1:               3.75rem !default;
$font-size-h2:               3rem !default;
$font-size-h3:               2.625rem !default;
$font-size-h4:               2.25rem !default;
$font-size-h5:               1.5625rem !default;
$font-size-h6:               1.5rem !default;

.headings-font-family {
  font-family: $headings-font-family;
}

.alt-font-family {
  font-family: $alt-font-family;
}
